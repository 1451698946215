import { Link } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { SEO_LINKS_DATA } from '~/components/layouts/footer/seoLinksData';
import { useLanguageParam } from '~/config/i18n';

const AvailableOffices = () => {
  const MOBILE_LIMIT = 10;
  const lang = useLanguageParam();
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  // const location = useLocation();
  // const page = isLocationHomepage(location) ? 'coworking' : t('routes.offices');
  const page = 'coworking';

  return (
    <div className="container container-md py-10 pb-lg-6 px-8">
      <p className="h5 fo-display-4 -medium mb-4">{t('global.links.seoLinks')}</p>
      <ul className="list-unstyled w-100 row m-0">
        {SEO_LINKS_DATA.map((seoLink, i) => (
          <li
            key={seoLink.label.fr}
            className={`text-2 -regular ps-0 col-12 col-md-6 col-lg-4 col-xxl-3 mb-md-4 ${
              !showMore && i >= MOBILE_LIMIT ? 'd-none d-md-block' : 'mb-4'
            }`}
          >
            <Link
              to={`/${lang}/${page}/${seoLink.link}`}
              target="_blank"
              className="text-decoration-underline link-underline-primary link-offset-2 link-underline-opacity-0 link-underline-opacity-50-hover"
            >
              <span className="d-none">{t('global.links.seoLinkPrefix')}</span> {seoLink.label[lang]}
            </Link>
          </li>
        ))}
      </ul>
      {SEO_LINKS_DATA.length > MOBILE_LIMIT && (
        <Button
          variant={'primaryOutline'}
          extraClasses={`d-flex d-md-none w-100`}
          onClick={() => setShowMore(!showMore)}
        >
          <span className="me-2 text-2">{t(`global.show${showMore ? 'Less' : 'More'}`)}</span>
          <SVGIcon iconType={`chevron-${showMore ? 'up' : 'down'}`} />
        </Button>
      )}
    </div>
  );
};

export default AvailableOffices;
