import AvailableOffices from './AvailableOffices';
import Copyright from './Copyright';
import Partners from './Partners';
const Footer = ({
  showAvailableOffices = true,
  extraClasses = '',
}: {
  showAvailableOffices?: boolean;
  extraClasses?: string;
}) => {
  return (
    <div className={`footer bg-grey-100 ${extraClasses}`}>
      <Partners />
      {showAvailableOffices && (
        <>
          <AvailableOffices />
          <div className="mx-4 fo-border-bottom-sm border-grey-200"></div>
        </>
      )}

      <Copyright />
    </div>
  );
};

export default Footer;
