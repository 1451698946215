import AboutUs from './AboutUs';

const Partners = () => {
  return (
    <div className="bg-light-grey-500">
      <div className="container container-md d-flex flex-lg-row flex-column justify-content-between py-10">
        <AboutUs showMobileFooter={true} />
        <div className="d-lg-none my-6 fo-border-bottom-sm border-grey-200"></div>
        <div className="d-flex justify-content-between align-items-center gap-2 gap-sm-12 w-100 w-md-auto">
          <img
            alt="luxproptech logo"
            src="/media/lux-prop-tech.png"
            className="partner-logo-md"
          />
          <img
            alt="the french proptech logo"
            src="/media/french-prop-tech.png"
            className="partner-logo-lg"
          />
          <img
            alt="France Digitale logo"
            src="/media/france-digitale.png"
            className="partner-logo-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
